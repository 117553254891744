<template>
    <div class="chartElem">
        <div style="margin: 4px">
            <div>
            <a>Business </a>
            <select  v-model="selected_business" @change="init_graph">
                <option v-for="(item,index_business) in this.list_business" v-bind:key="item">{{item}}</option>
            </select>
            </div>
            <div>
            <a>Periode </a>
            <select  v-model="selected_periode" @change="init_graph">
                <option v-for="(item,index_periode) in this.list_periode" v-bind:key="item">{{item}}</option>
            </select>
            </div>
        </div>
    <div class="row">
            <b-col sm="4" lg="4">
                <select  v-model="category1" @change="init_graph">
                    <option v-for="(item,index_category) in this.list_category" v-bind:key="item">{{item}}</option>
                </select>
                <highcharts class="chart" :options="chartOptions1" ></highcharts>
            </b-col>
            <b-col sm="4" lg="4">
                <select  v-model="category2" @change="init_graph">
                    <option v-for="(item,index_category) in this.list_category" v-bind:key="item">{{item}}</option>
                </select>
                <highcharts class="chart" :options="chartOptions2" ></highcharts>
            </b-col>
            <b-col sm="4" lg="4">
                <select  v-model="category3" @change="init_graph">
                    <option v-for="(item,index_category) in this.list_category" v-bind:key="item">{{item}}</option>
                </select>
                <highcharts class="chart" :options="chartOptions3" ></highcharts>
            </b-col>
        </div>
        <div class="row" style="margin-top: 20px">
            <b-col sm="12" lg="12">
                <highcharts class="chart" :options="chartOptions4" ></highcharts>
            </b-col>
        </div>
    </div>
</template>

<script>
    var dataPrev = {
        2016: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 11],
            ['Russia', 24],
            ['China', 38],
            ['Great Britain', 29],
            ['United States', 46]
        ],
        2012: [
            ['South Korea', 13],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 0],
            ['Russia', 22],
            ['China', 51],
            ['Great Britain', 19],
            ['United States', 36]
        ],
        2008: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 13],
            ['Russia', 27],
            ['China', 32],
            ['Great Britain', 9],
            ['United States', 37]
        ],
        2004: [
            ['South Korea', 0],
            ['Japan', 5],
            ['Australia', 16],
            ['Germany', 0],
            ['Russia', 32],
            ['China', 28],
            ['Great Britain', 0],
            ['United States', 36]
        ],
        2000: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 9],
            ['Germany', 20],
            ['Russia', 26],
            ['China', 16],
            ['Great Britain', 0],
            ['United States', 44]
        ]
    };

    var data = {
        2016: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 17],
            ['Russia', 19],
            ['China', 26],
            ['Great Britain', 27],
            ['United States', 46]
        ],
        2012: [
            ['South Korea', 13],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 0],
            ['Russia', 24],
            ['China', 38],
            ['Great Britain', 29],
            ['United States', 46]
        ],
        2008: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 0],
            ['Germany', 16],
            ['Russia', 22],
            ['China', 51],
            ['Great Britain', 19],
            ['United States', 36]
        ],
        2004: [
            ['South Korea', 0],
            ['Japan', 16],
            ['Australia', 17],
            ['Germany', 0],
            ['Russia', 27],
            ['China', 32],
            ['Great Britain', 0],
            ['United States', 37]
        ],
        2000: [
            ['South Korea', 0],
            ['Japan', 0],
            ['Australia', 16],
            ['Germany', 13],
            ['Russia', 32],
            ['China', 28],
            ['Great Britain', 0],
            ['United States', 36]
        ]
    };

    var countries = [{
        name: 'South Korea',
        flag: 197582,
        color: 'rgb(201, 36, 39)'
    }, {
        name: 'Japan',
        flag: 197604,
        color: 'rgb(201, 36, 39)'
    }, {
        name: 'Australia',
        flag: 197507,
        color: 'rgb(0, 82, 180)'
    }, {
        name: 'Germany',
        flag: 197571,
        color: 'rgb(0, 0, 0)'
    }, {
        name: 'Russia',
        flag: 197408,
        color: 'rgb(240, 240, 240)'
    }, {
        name: 'China',
        flag: 197375,
        color: 'rgb(255, 217, 68)'
    }, {
        name: 'Great Britain',
        flag: 197374,
        color: 'rgb(0, 82, 180)'
    }, {
        name: 'United States',
        flag: 197484,
        color: 'rgb(215, 0, 38)'
    }];


    function getData(data) {
        return data.map(function (country, i) {
            return {
                name: country[0],
                y: country[1],
                color: countries[i].color
            };
        });
    }
    export default {
        props: ['data_performance'],
        data () {
            return {
                title: 'FINANCIAL PERFORMANCE CONSOLIDATION YTD JUNI’20',
                list_business: [],
                selected_business: '',
                category1:'REVENUE',
                category2:'EBITDA',
                category3:'NI',
                selected_periode: 'mtd_actual',
                list_category: [],
                list_periode: [],
                points: [10, 0, 8, 2, 6, 4, 5, 5],
                chartType: 'solidgauge',
                seriesColor: '#6fcd98',
                colorInputIsSupported: null,
                animationDuration: 1000,
                updateArgs: [true, true, {duration: 1000}],
                chartOptions1: {
                    chart: {
                        type: 'solidgauge',
                        height: '110%',
                        // events: {
                        //     render: renderIcons
                        // }
                    },

                    title: {
                        text: this.category1,
                        style: {
                            fontSize: '24px'
                        }
                    },

                    tooltip: {
                        borderWidth: 0,
                        backgroundColor: 'none',
                        shadow: false,
                        style: {
                            fontSize: '16px'
                        },
                        valueSuffix: '%',
                        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
                        positioner: function (labelWidth) {
                            return {
                                x: (this.chart.chartWidth - labelWidth) / 2,
                                y: (this.chart.plotHeight / 2) + 15
                            };
                        }
                    },

                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '112%',
                            innerRadius: '88%',
                            backgroundColor: '#eaeaf6',
                            borderWidth: 0
                        }, { // Track for Exercise
                            outerRadius: '87%',
                            innerRadius: '63%',
                            backgroundColor: '#e3e3e3',
                            borderWidth: 0
                        }, { // Track for Stand
                            outerRadius: '62%',
                            innerRadius: '38%',
                            backgroundColor: '#d3daf3',
                            borderWidth: 0
                        }]
                    },

                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },

                    series: [{
                        name: '',
                        data: [{
                            color: '#eaeaf6',
                            innerRadius: '88%',
                            y: 0
                        }]
                    }, {
                        name: '',
                        data: [{
                            color: '#e3e3e3',
                            radius: '87%',
                            innerRadius: '63%',
                            y: 0
                        }]
                    }, {
                        name: this.category1,
                        data: [{
                            color: '#739cdd',
                            radius: '62%',
                            innerRadius: '38%',
                            y: 50
                        }]
                    }]
                },
                chartOptions2: {
                    chart: {
                        type: 'solidgauge',
                        height: '110%',
                        // events: {
                        //     render: renderIcons
                        // }
                    },

                    title: {
                        text: this.category2,
                        style: {
                            fontSize: '24px'
                        }
                    },

                    tooltip: {
                        borderWidth: 0,
                        backgroundColor: 'none',
                        shadow: false,
                        style: {
                            fontSize: '16px'
                        },
                        valueSuffix: '%',
                        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
                        positioner: function (labelWidth) {
                            return {
                                x: (this.chart.chartWidth - labelWidth) / 2,
                                y: (this.chart.plotHeight / 2) + 15
                            };
                        }
                    },

                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '112%',
                            innerRadius: '88%',
                            backgroundColor: '#eaeaf6',
                            borderWidth: 0
                        }, { // Track for Exercise
                            outerRadius: '87%',
                            innerRadius: '63%',
                            backgroundColor: '#e3e3e3',
                            borderWidth: 0
                        }, { // Track for Stand
                            outerRadius: '62%',
                            innerRadius: '38%',
                            backgroundColor: '#e5f5f5',
                            borderWidth: 0
                        }]
                    },

                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },

                    series: [{
                        name: '',
                        data: [{
                            color: '#eaeaf6',
                            innerRadius: '88%',
                            y: 0
                        }]
                    }, {
                        name: '',
                        data: [{
                            color: '#e3e3e3',
                            radius: '87%',
                            innerRadius: '63%',
                            y: 0
                        }]
                    }, {
                        name: this.category2,
                        data: [{
                            color: '#1cc2c4',
                            radius: '62%',
                            innerRadius: '38%',
                            y: 50
                        }]
                    }]
                },
                chartOptions3: {
                    chart: {
                        type: 'solidgauge',
                        height: '110%',
                        // events: {
                        //     render: renderIcons
                        // }
                    },

                    title: {
                        text: this.category3,
                        style: {
                            fontSize: '24px'
                        }
                    },

                    tooltip: {
                        borderWidth: 0,
                        backgroundColor: 'none',
                        shadow: false,
                        style: {
                            fontSize: '16px'
                        },
                        valueSuffix: '%',
                        pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
                        positioner: function (labelWidth) {
                            return {
                                x: (this.chart.chartWidth - labelWidth) / 2,
                                y: (this.chart.plotHeight / 2) + 15
                            };
                        }
                    },

                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '112%',
                            innerRadius: '88%',
                            backgroundColor: '#eaeaf6',
                            borderWidth: 0
                        }, { // Track for Exercise
                            outerRadius: '87%',
                            innerRadius: '63%',
                            backgroundColor: '#e3e3e3',
                            borderWidth: 0
                        }, { // Track for Stand
                            outerRadius: '62%',
                            innerRadius: '38%',
                            backgroundColor: '#ddf3e7',
                            borderWidth: 0
                        }]
                    },

                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: []
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },

                    series: [{
                        name: '',
                        data: [{
                            color: '#eaeaf6',
                            innerRadius: '88%',
                            y: 0
                        }]
                    }, {
                        name: '',
                        data: [{
                            color: '#e3e3e3',
                            radius: '87%',
                            innerRadius: '63%',
                            y: 0
                        }]
                    }, {
                        name: this.category3,
                        data: [{
                            color: '#199f53',
                            radius: '62%',
                            innerRadius: '38%',
                            y: 50
                        }]
                    }]
                },
                chartOptions4: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    plotOptions: {
                        series: {
                            grouping: false,
                            borderWidth: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} %</b><br/>'
                    },
                    xAxis: {
                        type: 'category',
                        max: 4,
                        labels: {
                            useHTML: true,
                            animate: true,
                            formatter: function () {
                                var value = this.value,
                                    output;

                                countries.forEach(function (country) {
                                    if (country.name === value) {
                                        output = country.flag;
                                    }
                                });

                                return value;
                            }
                        }
                    },
                    yAxis: [{
                        title: {
                            text: '%'
                        },
                        showFirstLabel: false
                    }],
                    series: [
                        {
                            name: 'Mtd Ach',
                            id: 'main',
                            color: '#199f53',
                            pointPlacement: -0.8,
                            // dataSorting: {
                            //     enabled: true,
                            //     matchByName: true
                            // },
                            dataLabels: [{
                                enabled: true,
                                inside: true,
                                style: {
                                    fontSize: '16px'
                                }
                            }],
                            data: [
                                ['Revenue', 80],
                                ['Coe', 0],
                                ['Ebitda', 40],
                                ['Ebit', 77],
                                ['Ni', 29],
                                ['Ebitda Margin', 36],
                                ['Ebit Margin', 37],
                                ['Ni MArgin', 56]

                            ]
                        }],
                    exporting: {
                        allowHTML: true
                    }
                },
                chartOptions5: {
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    plotOptions: {
                        series: {
                            grouping: false,
                            borderWidth: 0
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        headerFormat: '<span style="font-size: 15px">{point.point.name}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y} %</b><br/>'
                    },
                    xAxis: {
                        type: 'category',
                        max: 4,
                        labels: {
                            useHTML: true,
                            animate: true,
                            formatter: function () {
                                var value = this.value,
                                    output;

                                countries.forEach(function (country) {
                                    if (country.name === value) {
                                        output = country.flag;
                                    }
                                });

                                return value;
                            }
                        }
                    },
                    yAxis: [{
                        title: {
                            text: '%'
                        },
                        showFirstLabel: false
                    }],
                    series: [
                        {
                            name: 'Previous Month',
                            color: '#1cc2c4',
                            pointPlacement: -0,
                            linkedTo: 'main',
                            data: [
                                ['Revenue', 40],
                                ['Coe', 0],
                                ['Ebitda', 0],
                                ['Ebit', 17],
                                ['Ni', 19],
                                ['Ebitda Margin', 26],
                                ['Ebit Margin', 27],
                                ['Ni MArgin', 46]
                            ]
                        },
                        {
                            name: 'Mtd Rkapp',
                            color: '#739cdd',
                            pointPlacement: -0.2,
                            linkedTo: 'main',
                            data: [
                                ['Revenue', 50],
                                ['Coe', 0],
                                ['Ebitda', 0],
                                ['Ebit', 17],
                                ['Ni', 19],
                                ['Ebitda Margin', 26],
                                ['Ebit Margin', 27],
                                ['Ni MArgin', 46]
                            ]
                        },
                        {
                            name: 'Mtd Actual',
                            color: '#906877',
                            pointPlacement: -0.4,
                            linkedTo: 'main',
                            data: [
                                ['Revenue', 60],
                                ['Coe', 0],
                                ['Ebitda', 0],
                                ['Ebit', 17],
                                ['Ni', 19],
                                ['Ebitda Margin', 26],
                                ['Ebit Margin', 27],
                                ['Ni MArgin', 46]
                            ]
                        },
                        {
                            name: 'Mtd Mom',
                            color: '#496190',
                            pointPlacement: -0.6,
                            linkedTo: 'main',
                            data: [
                                ['Revenue', 50],
                                ['Coe', 0],
                                ['Ebitda', 0],
                                ['Ebit', 17],
                                ['Ni', 19],
                                ['Ebitda Margin', 26],
                                ['Ebit Margin', 27],
                                ['Ni MArgin', 46]
                            ]
                        },
                        {
                        name: 'Mtd Ach',
                        id: 'main',
                        color: '#199f53',
                            pointPlacement: -0.8,
                        // dataSorting: {
                        //     enabled: true,
                        //     matchByName: true
                        // },
                        dataLabels: [{
                            enabled: true,
                            inside: true,
                            style: {
                                fontSize: '16px'
                            }
                        }],
                        data: [
                            ['Revenue', 80],
                            ['Coe', 0],
                            ['Ebitda', 40],
                            ['Ebit', 77],
                            ['Ni', 29],
                            ['Ebitda Margin', 36],
                            ['Ebit Margin', 37],
                            ['Ni MArgin', 56]

                        ]
                    }],
                    exporting: {
                        allowHTML: true
                    }
                }
            }
        },
        mounted () {
            this.list_business = Object.keys(this.data_performance)
            if (this.list_business.length>0) {
                this.selected_business = this.list_business[0]
                this.list_category=[]
                for (let j = 0; j < this.data_performance[this.selected_business].length; j++) {
                    this.list_category.push(this.data_performance[this.selected_business][j].name)
                }
                let list_keys = Object.keys(this.data_performance[this.selected_business][0])
                this.list_periode = []
                for (let i = 0; i < list_keys.length; i++){
                    if (list_keys[i] != 'name' && list_keys[i] != 'business' && list_keys[i] != 'id')
                    this.list_periode.push(list_keys[i])

                }
            }
            this.init_graph()
        },
        watch: {
            title (newValue) {
                this.chartOptions.title.text = newValue
            },
            points (newValue) {
                this.chartOptions.series[0].data = newValue
            },
            chartType (newValue) {
                this.chartOptions.chart.type = newValue.toLowerCase()
            },
            seriesColor (newValue) {
                this.chartOptions.series[0].color = newValue.toLowerCase()
            },
            animationDuration (newValue) {
                this.updateArgs[2].duration = Number(newValue)
            }
        },
        methods:{
            init_graph(){
                this.chartOptions1.title.text = this.category1
                this.chartOptions2.title.text = this.category2
                this.chartOptions3.title.text = this.category3
                this.chartOptions1.series[2].name = this.category1
                this.chartOptions2.series[2].name = this.category2
                this.chartOptions3.series[2].name = this.category3
                this.chartOptions1.series[2].data[0].y = this.get_value(this.category1)
                this.chartOptions2.series[2].data[0].y = this.get_value(this.category2)
                this.chartOptions3.series[2].data[0].y = this.get_value(this.category3)
                this.chartOptions4.series[0].data = []
                for (let i = 0; i < this.list_category.length; i++){
                    this.chartOptions4.series[0].data.push([
                        this.list_category[i],
                        this.get_value(this.list_category[i])
                    ])
                }

            },
            get_value(category){
                for (let j = 0; j < this.data_performance[this.selected_business].length; j++) {
                    if (this.data_performance[this.selected_business][j].name === category){
                        return this.data_performance[this.selected_business][j][this.selected_periode]
                    }
                }
                return 0
            }
        },

    }
</script>

<style scoped>
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0;
    }
    #colorPicker {
        border: 0;
        padding: 0;
        margin: 0;
        width: 30px;
        height: 30px;
    }
    .numberInput {
        width: 30px;
    }
</style>
